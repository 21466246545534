import React from "react";
import "./App.scss";
import logo from "./images/usdclogo.png";

function App() {
  return (
    <div className="App">
      <div className="logo">
        <img src={logo} width="250" height="250" alt="usdc logo" />
      </div>
      <div className="sale">
        <h1>USDC RATES</h1>
        <h2>usdcrates.com</h2>
        <p>
          The owner of <strong>usdcrates.com</strong> is offering the domain for
          sale for an asking price of $250 USD!
        </p>
        <h2>
          <a
            href="mailto:contact@usdcrates.com?subject=I want to buy USDCRATES.com from you&body=*insert your offer here*"
            target="_blank"
            rel="noreferrer"
          >
            Buy this domain!
          </a>
        </h2>
      </div>
    </div>
  );
}

export default App;
